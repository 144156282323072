import React, { useState } from 'react';
import { Menu, MenuItem, IconButton } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useNavigate } from 'react-router-dom';
import AddEditPatient from './AddEditPatient';

const MenuComponent = ({ patientId }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [isEditPatientOpen, setEditPatientOpen] = useState(false);
    const navigate = useNavigate();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleNavigate = (path) => {
        navigate(path, { state: { patientId } });
        handleMenuClose();
    };

    const handleEditPatient = () => {
        setEditPatientOpen(true);
        handleMenuClose();
    };

    return (
        <span className="menu-container">
            <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleMenuClick}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleMenuClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => handleNavigate('/NewDocument')}>Import Document</MenuItem>
                <MenuItem onClick={() => handleNavigate('/NewText')}>Import Text</MenuItem>
                <MenuItem onClick={() => handleNavigate('/NewSession')}>Record Session</MenuItem>
                <MenuItem onClick={handleEditPatient}>Edit Patient</MenuItem>
            </Menu>
            <AddEditPatient
                isEditPatientOpen={isEditPatientOpen}
                setEditPatientOpen={setEditPatientOpen}
                patientId={patientId}
            />
        </span>
    );
};

export default MenuComponent;