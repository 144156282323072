import React, { useEffect, useState } from 'react';
import { getData, updateDataFunction, removeFromArray } from './utils/firestoreService';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './utils/authContext';
import MenuComponent from './utils/menuComponent';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Pagination from '@mui/material/Pagination';
import AddEditPatient from './utils/AddEditPatient';

const Dashboard = () => {
    const [patients, setPatients] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;
    const navigate = useNavigate();
    const [isEditPatientOpen, setEditPatientOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const documentsPerPage = 50;

    const fetchPatients = async () => {
        try {
            if (!currentUser) return;

            const userDoc = await getData(`/users/${userId}`);

            const patientIds = userDoc.patientIds || [];

            if (patientIds.length === 0) {
                setPatients([]);
                return;
            }

            const patientPromises = patientIds.map(id => getData(`/patients/${id}`));
            const patientsList = await Promise.all(patientPromises);

            const pinnedPatients = patientsList.filter(patient => 
                patient && 
                patient.pin === true && 
                patient.isArchived !== true
            );

            setPatients(pinnedPatients);
        } catch (error) {
            console.error("Error fetching patients:", error);
        }
    };

    useEffect(() => {
        fetchPatients();
    }, [userId]);

    const handlePatientClick = (patient) => {
        navigate('/Patient', { state: { patientId: patient.id } });
    };

    const handleRecordClick = (patient) => {
        navigate('/NewSession', { state: { patientId: patient.id } });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const handleDeletePatient = async (patientId) => {
        const confirmDelete = window.confirm('Are you sure you want to remove this patient from your list?');
        if (!confirmDelete) return;

        if (currentUser) {
            try {
                const updateObj = removeFromArray('patientIds', patientId);
                await updateDataFunction(`/users/${userId}`, updateObj);

                setPatients((prevPatients) =>
                    prevPatients.filter((patient) => patient.id !== patientId)
                );
            } catch (error) {
                console.error('Error removing patient:', error);
            }
        }
    };

    const filteredPatients = patients.filter(patient =>
        `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(searchQuery.toLowerCase()) &&
        !patient.isArchived
    );
    const indexOfLastPatient = currentPage * documentsPerPage;
    const indexOfFirstPatient = indexOfLastPatient - documentsPerPage;
    const currentPatients = filteredPatients.slice(indexOfFirstPatient, indexOfLastPatient);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        <div className='page-container'>
            <div className='header-container'>
                <div className='page-title'>Welcome to Juniper</div>
                <div className='header-search'>
                    <div className='header-search-wrapper'>
                        <SearchIcon className='header-search-icon' />
                        <input
                            type="text"
                            placeholder="Search Patients"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className='header-search-input'
                        />
                    </div>
                </div>
                <div title="Add Patient" className="header-button" onClick={() => setEditPatientOpen(true)}>
                    <PersonAddIcon className="header-icon" /> Add Patient
                </div>
                <AddEditPatient
                    setPatients={setPatients}
                    isEditPatientOpen={isEditPatientOpen}
                    setEditPatientOpen={setEditPatientOpen}
                />
            </div>
            <div className='standard-container'>
                <h3>Patients</h3>
                <table className='standard-table thinner-rules'>
                    <thead>
                        <tr>
                            <th></th>

                            <th className='column-5'></th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPatients.length === 0 ? (
                            <tr>
                                <td colSpan="4" style={{ textAlign: 'center' }}>
                                    No patients to display. Add one above...
                                </td>
                            </tr>
                        ) : (
                            currentPatients.map(patient => (
                                <tr key={patient.id}>
                                    <td onClick={() => handlePatientClick(patient)} style={{ cursor: 'pointer' }}>
                                        <span className="table-primary-text">
                                            {patient.firstName} {patient.lastName.charAt(0)}.
                                        </span>
                                        {patient.dateOfBirth && (
                                            <span className="table-secondary-text"> DOB: {patient.dateOfBirth}</span>
                                        )}
                                        {patient.pronoun && (
                                            <span className="table-secondary-text"> Pronouns: {patient.pronoun}</span>
                                        )}
                                    </td>
                                    {/* <td>
                                        <DeleteIcon className='table-icon-lighter' onClick={() => handleDeletePatient(patient.id)} title="Delete Patient?"></DeleteIcon>
                                    </td> */}
                                    <td> <MenuComponent patientId={patient.id} /></td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
                <div className="pagination-controls">
                    <Pagination
                        count={Math.ceil(filteredPatients.length / documentsPerPage)}
                        page={currentPage}
                        onChange={handlePageChange}
                        className="pagination"
                        color="primary"
                        variant="outlined"
                        size="small"
                    />
                </div>
            </div>
        </div>
    );
};

export default Dashboard;