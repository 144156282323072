import { db } from './firebase';
import { collection, query, where, addDoc, getDoc, getDocs, doc, updateDoc, deleteDoc, arrayUnion, arrayRemove  } from 'firebase/firestore';

// Add a new document to a collection
export const addData = async (collectionName, data) => {
    try {
        const docRef = await addDoc(collection(db, collectionName), data);
        return docRef.id;
    } catch (error) {
        console.error("Error adding document: ", error);
        throw error;
    }
};

// Get all documents from a collection
export const getDocuments = async (collectionName) => {
    try {
        const querySnapshot = await getDocs(collection(db, collectionName));
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error("Error getting documents: ", error);
        throw error;
    }
};

// Get data from a document
export const getData = async (documentPath) => {
    try {
        const docRef = doc(db, documentPath);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            console.error("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document: ", error);
        throw error;
    }
};

// Query documents by type
export const getDocumentsByType = async (collectionName, typeId) => {
    try {
        const q = query(collection(db, collectionName), where("typeId", "==", typeId));
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    } catch (error) {
        console.error("Error querying documents by type: ", error);
        throw error;
    }
};

// Update a document in a collection
export const updateData = async (collectionName, id, data) => {
    try {
        const docRef = doc(db, collectionName, id);
        await updateDoc(docRef, data);
        return docRef.id;
    } catch (error) {
        console.error("Error updating document: ", error);
        throw error;
    }
};

// Update a data with a function
export const updateDataFunction = async (documentPath, updateFunction) => {
    try {
        const docRef = doc(db, documentPath);
        await updateDoc(docRef, updateFunction);
    } catch (error) {
        console.error("Error updating document: ", error);
        throw error;
    }
};

// Delete a document from a collection
export const deleteData = async (collectionName, id) => {
    try {
        const docRef = doc(db, collectionName, id);
        await deleteDoc(docRef);
    } catch (error) {
        console.error("Error deleting document: ", error);
        throw error;
    }
};

// Get a single document
export const getDocument = async (collectionName, documentId) => {
    try {
        const docRef = doc(db, collectionName, documentId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            return { id: docSnap.id, ...docSnap.data() };
        } else {
            console.error("No such document!");
            return null;
        }
    } catch (error) {
        console.error("Error getting document: ", error);
        throw error;
    }
};

// Update user settings
export const updateUserSettings = async (userId, newSettings) => {
    try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
            settings: newSettings
        });
        console.log('User settings updated successfully');
    } catch (error) {
        console.error('Error updating user settings:', error);
        throw error;
    }
};

// Fetch user settings
export const getUserSettings = async (userId) => {
    try {
        const userRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists()) {
            return userDoc.data().settings;
        } else {
            console.error('No such user!');
            return null;
        }
    } catch (error) {
        console.error('Error fetching user settings:', error);
        throw error;
    }
};

// array functions
export const removeFromArray = (field, valueToRemove) => {
    return {
        [field]: arrayRemove(valueToRemove),
    };
};

export const addToArray = (field, valueToAdd) => {
    return {
        [field]: arrayUnion(valueToAdd),
    };
};