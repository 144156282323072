import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from './utils/authContext';
import logo from './assets/juniper-logo.png';
import { companyName, versionNum } from './globals';

import './css/Sidebar.css';

const Sidebar = () => {
    const { currentUser, signOut } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();

    const handleLogout = async () => {
        try {
            await signOut();
            navigate('/');
        } catch (error) {
            alert(error.message);
        }
    };

    const isActive = (path) => location.pathname === path;


    return (
        <div className="sidebar">

            <Link to="/dashboard">
                <img src={logo} alt="Logo" className="logo-sidebar" />
            </Link>

            <div className="user-info">Welcome:
                <p>{currentUser ? currentUser.email : 'Guest'}</p>
            </div>
            <ul>
                <li className={isActive('/dashboard') ? 'active' : ''}><Link to="/dashboard">Today</Link></li>
                <li className={isActive('/settings') ? 'active' : ''}><Link to="/settings">Settings</Link></li>
            </ul>
            <button className="logout-button" onClick={handleLogout}>Sign Out</button>
            <div className="sidebar-footer">
                &copy; {new Date().getFullYear()} {companyName} v{versionNum}.<br />All rights reserved.
            </div>
        </div>
    );
};

export default Sidebar;
