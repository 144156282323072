import React, { useState, useEffect } from 'react';
import { addData, getData, updateDataFunction, addToArray } from './firestoreService';
import { useAuth } from './authContext';

const AddEditPatient = ({ setPatients = () => {}, isEditPatientOpen, setEditPatientOpen, patientId }) => {
    const [newPatientFirstName, setNewPatientFirstName] = useState('');
    const [newPatientLastName, setNewPatientLastName] = useState('');
    const [newPatientDOB, setNewPatientDOB] = useState('');
    const [newPatientGender, setNewPatientGender] = useState('');
    const [newPatientPronoun, setNewPatientPronoun] = useState('');
    const [dobError, setDobError] = useState('');
    const { currentUser } = useAuth();
    const userId = currentUser?.uid;

    const datePattern = /^(0[1-9]|1[0-2])[-\/](0[1-9]|[12][0-9]|3[01])[-\/](\d{4})$/;

    useEffect(() => {
        const fetchPatientData = async () => {
            if (patientId) {
                try {
                    const patientData = await getData(`/patients/${patientId}`);
                    if (patientData) {
                        setNewPatientFirstName(patientData.firstName);
                        setNewPatientLastName(patientData.lastName);
                        setNewPatientDOB(patientData.dateOfBirth);
                        setNewPatientGender(patientData.gender);
                        setNewPatientPronoun(patientData.pronoun);

                    }
                } catch (error) {
                    console.error('Error fetching patient data:', error);
                }
            }
        };

        fetchPatientData();
    }, [patientId]);

    const handleAddPatient = async () => {
        if (!newPatientFirstName.trim()) {
            console.error('First name cannot be empty');
            return;
        }
    
        if (!datePattern.test(newPatientDOB)) {
            console.error('Invalid Date of Birth format');
            return;
        }
    
        const patientData = {
            firstName: newPatientFirstName,
            lastName: newPatientLastName,
            dateOfBirth: newPatientDOB,
            gender: newPatientGender,
            pronoun: newPatientPronoun,
            modifiedAt: new Date()
        };
    
        try {
            if (patientId) {
                // Update existing patient
                await updateDataFunction(`/patients/${patientId}`, patientData);
                
                if (setPatients) {
                    setPatients((prevPatients) => 
                        prevPatients.map(patient => 
                            patient.id === patientId 
                                ? { ...patient, ...patientData }
                                : patient
                        )
                    );
                }
            } else {
                // Create new patient
                const newPatientId = await addData(`/patients`, {
                    ...patientData,
                    createdAt: new Date(),
                    pin: true
                });
    
                    if (setPatients) {
                        setPatients((prevPatients) => [
                            ...prevPatients,
                            { id: newPatientId, ...patientData },
                        ]);
                    }
    
                await updateDataFunction(`/users/${userId}`, addToArray('patientIds', newPatientId));
            }
    
            // Reset form and close modal
            setNewPatientFirstName('');
            setNewPatientLastName('');
            setNewPatientDOB('');
            setNewPatientGender('');
            setNewPatientPronoun('');
            setEditPatientOpen(false);
    
        } catch (error) {
            console.error('Error saving patient:', error);
        }
    };

    const handleArchivePatient = async () => {
        try {
            await updateDataFunction(`/patients/${patientId}`, {
                isArchived: true,
                modifiedAt: new Date()
            });
            
            if (setPatients) {
                setPatients((prevPatients) => 
                    prevPatients.filter(patient => patient.id !== patientId)
                );
            }
            
            setEditPatientOpen(false);
        } catch (error) {
            console.error('Error archiving patient:', error);
        }
    };

    const handleDOBBlur = () => {
        if (!datePattern.test(newPatientDOB)) {
            setDobError('Invalid Date of Birth format');
        } else {
            setDobError('');
        }
    };

    const isFormValid = () => {
        return newPatientFirstName.trim() && newPatientLastName.trim() && datePattern.test(newPatientDOB) && newPatientGender && newPatientPronoun;
    };

    return (
        isEditPatientOpen && (
            <div className="modal" onClick={() => setEditPatientOpen(false)}>
                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                    <div className='modal-title'>{patientId ? 'Edit Patient' : 'Add New Patient'}</div>
                    <div className='modal-form'>
                        <input
                            type="text"
                            placeholder="First Name"
                            value={newPatientFirstName}
                            onChange={(e) => setNewPatientFirstName(e.target.value)}
                            className='modal-input'
                        />
                        <input
                            type="text"
                            placeholder="Last Name"
                            value={newPatientLastName}
                            onChange={(e) => setNewPatientLastName(e.target.value)}
                            className='modal-input'
                        />
                        <input
                            type="text"
                            placeholder="Date of Birth (MM/DD/YYYY)"
                            value={newPatientDOB}
                            onChange={(e) => setNewPatientDOB(e.target.value)}
                            onBlur={handleDOBBlur}
                            className='modal-input'
                        />
                        {dobError && <div className="modal-error-message">{dobError}</div>}
                        <div className='modal-radio'>Gender Identity:
                            <label>
                                <input
                                    type="radio"
                                    value="Male"
                                    checked={newPatientGender === 'Male'}
                                    onChange={(e) => setNewPatientGender(e.target.value)}
                                />
                                Male
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Female"
                                    checked={newPatientGender === 'Female'}
                                    onChange={(e) => setNewPatientGender(e.target.value)}
                                />
                                Female
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Non-binary"
                                    checked={newPatientGender === 'Non-binary'}
                                    onChange={(e) => setNewPatientGender(e.target.value)}
                                />
                                Non-binary
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    value="Prefer not to say"
                                    checked={newPatientGender === 'Prefer not to say'}
                                    onChange={(e) => setNewPatientGender(e.target.value)}
                                />
                                Prefer not to say
                            </label>
                        </div>
                        <div className='modal-dropdown'>
                            <label>Pronouns:
                                <select
                                    value={newPatientPronoun}
                                    onChange={(e) => setNewPatientPronoun(e.target.value)}
                                    className='modal-select'
                                >
                                    <option value="">Select Pronouns</option>
                                    <option value="he/him">He/Him</option>
                                    <option value="she/her">She/Her</option>
                                    <option value="they/them">They/Them</option>
                                </select>
                            </label>
                        </div>
                        <div className="modal-button-container">
                        <button className="button-cancel" onClick={() => setEditPatientOpen(false)}>
                            Cancel
                        </button>
                        {patientId && (
                            <button 
                                className="button-delete" 
                                onClick={handleArchivePatient}
                            >
                                Delete Patient
                            </button>
                        )}
                        <button
                            className="button-continue"
                            onClick={handleAddPatient}
                            disabled={!isFormValid()}
                        >
                            {patientId ? 'Update Patient' : 'Add Patient'}
                        </button>
                    </div>
                    </div>
                </div>
            </div>
        )
    );
};

export default AddEditPatient;