import React from 'react';

const Company = () => {
    return (
        <div className='page-container'>
            <div className='page-title'>Settings Page</div>
            <p>I'll be adding controls here to override settings like fields that may have been hidden.</p>
        </div>
    );
};

export default Company;
