import { processHistory } from "./historyProcessing";

export const capitalizeAndFormat = (str) => {
  return str
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const generateSectionExport = (section, documentData, dataHistory) => {
  switch (section.type) {
    case "EditableTextSection":
      return {
        [section.dataField]: {
          data: documentData[section.dataField],
          title: section.title,
        },
      };  
       case "EditableDataSection":
      const dataItems = documentData[section.data];
      const historyData = processHistory(dataHistory, section.data);
      let formattedData = "<ul>";
    
      section.fields.forEach((field) => {
        let value =
          dataItems && dataItems[field]
            ? dataItems[field]
            : historyData && historyData[field] && historyData[field][0].value
            ? historyData[field][0].value
            : section.defaultExport;
    
        if (value === undefined || value === null) {
          value = "";
        }
    
        if (section.exportType !== "hideEmpty" || value !== "") {
          formattedData += `<li><strong>${capitalizeAndFormat(
            field
          )}:</strong> ${value}</li>`;
        }
      });
    
      formattedData += "</ul>";
      return {
        [section.data]: {
          data: formattedData,
          title: section.title,
        },
      };

    case "EditableTableSection":      
      const tableData = documentData[section.subData];

      if (!tableData || tableData.length === 0) {
        return {};
      }

    let formattedTableData = "<ul>";
    let currentItems = "";
    let pastItems = "";
    
    tableData.forEach((item) => {
      if (item.isDeleted || item.isHidden) {
        return;
      }
    
      if (section.exportType === "currentOnly" && !item.current) {
        return;
      }
    
      let listItem = "<li>";
      let firstColumn = false;
    
      Object.keys(section.fields).forEach((fieldKey, index, array) => {
        const field = section.fields[fieldKey];
        if (item[fieldKey] && field.type != "check") {
          if (!firstColumn) {
            listItem += `<strong>${capitalizeAndFormat(item[fieldKey])}:</strong> `;
            firstColumn = true;
          } else {
            listItem += `${item[fieldKey]}`;
            if (index < array.length - 1) {
              listItem += ", ";
            }
          }
        }
      });
      listItem += "</li>";
    
      if (section.exportType === "splitCurrentPast") {
        if (item.current) {
          currentItems += listItem;
        } else {
          pastItems += listItem;
        }
      } else {
        formattedTableData += listItem;
      }
    });
    
    if (section.exportType === "splitCurrentPast") {
      formattedTableData += "Current Items:";
      formattedTableData += currentItems;
      formattedTableData += "<br/>Past Items:";
      formattedTableData += pastItems;
    }
    
    formattedTableData += "</ul>";
    
      return {
        [section.subData]: {
          data: formattedTableData,
          title: section.title,
        },
      };

    case "Confirmation":
    console.log('confirmation section');
      const confirmationMessage = documentData[section.dataField]?.exportMessage || "";
      return {
        [section.dataField]: {
          data: confirmationMessage,
          title: "",
        },
      };

    default:
      return null;
  }
};
