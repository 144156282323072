import {  generateSectionExport } from './exportUtils';

export const documentExport = (documentData, documentType, dataHistory) => {
    const exports = {};

    documentType.sections.forEach(section => {
        if (section.exports && section.exports.length > 0) {
            section.exports.forEach(exportType => {
                if (!exports[exportType]) {
                    exports[exportType] = {};
                }
                const sectionExport = generateSectionExport(section, documentData, dataHistory);
                if (sectionExport) {
                    Object.assign(exports[exportType], sectionExport);
                }
            });
        }
    });
    return exports;
};