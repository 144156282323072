//add all history processors here

// standard, combined and one that doesn't require a field key

//standard full history processor
export const processHistory = (dataHistory, originalFieldKey) => {
    if (!dataHistory) return;

    const fullHistoryData = {};

    for (let i = dataHistory.length - 1; i >= 0; i--) {
        const record = dataHistory[i];
        const fieldData = record[originalFieldKey];
        const date = record.modifiedAt;
        for (const key in fieldData) {
            if (fieldData[key] !== "") {
                if (!fullHistoryData[key]) {
                    fullHistoryData[key] = [];
                }
                fullHistoryData[key].push({ value: fieldData[key], date: date });
            }
        }
    }

    return fullHistoryData;
};

// individual field processor with field key
export const processHistoryData = (history, fieldKey) => {
    let historyDataItem = '';
    let historyData = [];

    if (history && fieldKey && Array.isArray(history[fieldKey])) {
        const sortedData = history[fieldKey].sort((a, b) => {
            if (a.date.seconds !== b.date.seconds) {
                return b.date.seconds - a.date.seconds;
            }
            return b.date.nanoseconds - a.date.nanoseconds;
        });

        if (sortedData.length > 0) {
            historyDataItem = sortedData[0].value;
        }

        historyData = sortedData.map(item => ({
            value: item.value,
            date: item.date
        }));
    }

    return { historyDataItem, historyData };
};

// process history data without field key
export const processHistoryMatch = (history, getKey) => {
    let historyData = [];
    let recordMap = new Map();

    history.forEach(record => {
        Object.keys(record).forEach(key => {
            if (key !== 'modifiedAt') {
                let item = record[key];
                let primaryValue = getKey(item);
                let modifiedAt = record.modifiedAt.seconds * 1000 + Math.floor(record.modifiedAt.nanoseconds / 1000000);
                if (!recordMap.has(primaryValue) || recordMap.get(primaryValue).modifiedAt < modifiedAt) {
                    recordMap.set(primaryValue, { ...item, modifiedAt, isHistory: true });
                }
            }
        });
    });

    historyData = Array.from(recordMap.values()).map(item => {
        return item;
    });

    return historyData;
};