import { getDocument, getDocuments } from './firestoreService';

export const fetchPatientData = async (patientId, setPatientData, setPatientDocuments) => {
    try {
        const patientData = await getDocument(`patients`, patientId);

        if (patientData) {
            if (setPatientData) {
                setPatientData(patientData);
            }

            if (setPatientDocuments) {
                let documents = await getDocuments(`patients/${patientId}/documents`);

                documents = documents.sort((a, b) => b.modifiedAt.seconds - a.modifiedAt.seconds);

                setPatientDocuments(documents);
            }
        } else {
            console.error('No such patient!');
        }
    } catch (error) {
        console.error('Error fetching patient data:', error);
    }
};