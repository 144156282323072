import { getDocument, getDocumentsByType, getDocuments } from './firestoreService';

export const fetchDocumentData = async (patientId, docId, setPersonName, setDocumentData, setTitle, setDocumentHistory) => {
    try {
            const patientData = await getDocument(`patients`, patientId);

            const limitDocuments = false; // this will be a user setting later. For now, use all documents for history.

            if (patientData) {
                const formattedPersonName = `${patientData.firstName} ${patientData.lastName}`; 
                setPersonName(formattedPersonName);
                const documentData = await getDocument(`patients/${patientId}/documents`, docId);
                setDocumentData(documentData);
                setTitle(documentData.title);
                
                if ((documentData.typeId === 1 || documentData.typeId === 2) && limitDocuments) {  //limits to current audio recorded docs
                    const previousDocumentsType1 = await getDocumentsByType(`patients/${patientId}/documents`, 1);
                    const previousDocumentsType2 = await getDocumentsByType(`patients/${patientId}/documents`, 2);
                    const previousDocuments = [...previousDocumentsType1, ...previousDocumentsType2];
                    const filteredDocuments = previousDocuments.filter(doc => doc.id !== docId && doc.modifiedAt <= documentData.modifiedAt);
                    if (filteredDocuments.length > 0) {
                        setDocumentHistory(filteredDocuments);
                    }
                } else {

                    const previousDocuments = await getDocuments(`patients/${patientId}/documents`);

                    const filteredDocuments = previousDocuments.filter(doc => doc.id !== docId && doc.modifiedAt <= documentData.modifiedAt);
                    if (filteredDocuments.length > 0) {
                        setDocumentHistory(filteredDocuments);
                    }
                }

            } else {
                console.error('No such patient!');
            }
        
    } catch (error) {
        console.error('Error fetching document data:', error);
    }
};