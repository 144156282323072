// Excel style wide table editor for complex objects, with option to match the Data Section editor

import React, { useState, useRef, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { updateData } from '../utils/firestoreService';
import { generateSectionExport } from '../utils/exportUtils';
import {processHistoryMatch} from '../utils/historyProcessing';
import Tooltip from '@mui/material/Tooltip';    
import AddIcon from '@mui/icons-material/AddBox';
import AddBoxIcon from '@mui/icons-material/AddBoxOutlined';
import RemoveIcon from '@mui/icons-material/IndeterminateCheckBox';
import VisibilityIcon from  '@mui/icons-material/VisibilityOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffOutlined';
import DeleteIcon from '@mui/icons-material/DisabledByDefault';
import debounce from 'lodash/debounce';
import { v4 as uuidv4 } from 'uuid';

const iconMedium = { cursor: 'pointer', marginLeft: '0.4rem', fontSize: '1rem' };
const iconSmall = { cursor: 'pointer', marginLeft: '0rem', fontSize: '0.9rem' };
const rowHeightAddition = 8; 

const EditableTableSection = ({ section, sectionKey, data, dataHistory, patientId, docId, copyToClipboard, ContentCopyIcon }) => {
    const [isOpen, setIsOpen] = useState(section.defaultOpen || true);
    const [editedData, setEditedData] = useState(data[section.subData]);
    const [hiddenRowsVisible, setHiddenRowsVisible] = useState(false);
    const [historyData, setHistoryData] = useState([]);
    const [combinedData, setCombinedData] = useState([]);
    const displayFields = Object.keys(section.fields);
    const hasRunRef = useRef(false);
    const debounceDelay = 1000;

    useEffect(() => {
        const updateHistoryData = async () => {
            if (section.displayHistory && dataHistory) {
                const filteredHistory = dataHistory.map(record => ({
                    ...record[section.subData],
                    modifiedAt: record.modifiedAt
                }));
    
                const getKey = (item) => item.id || item[section.primaryField];
                const processedHistory = await processHistoryMatch(filteredHistory, getKey);
                setHistoryData(processedHistory);
            }
        };
        updateHistoryData();
    }, [section.displayHistory, dataHistory]);
    
    useEffect(() => {
            if (hasRunRef.current) {
                processData();
                resizeTextArea();
            } else {
                processData();
                hasRunRef.current = true;
            }
    }, [isOpen, editedData, historyData]);

    const processData = () => {
        const uniqueDataMap = new Map();
    
        const getKey = (item) => item[section.primaryField] || item.id;
    
        if (editedData && editedData.length > 0) {
            editedData.forEach(item => {
                uniqueDataMap.set(getKey(item), item);
            });
        }
    
        if (historyData && historyData.length > 0) {
            historyData.forEach(item => {
                if (!uniqueDataMap.has(getKey(item))) {
                    uniqueDataMap.set(getKey(item), item);
                }
            });
        }
    
        let combinedData = Array.from(uniqueDataMap.values());
    
        switch (section.sort) {
            case "alphabetical":
                combinedData.sort((a, b) => {
                    const fieldA = (a.id || a[section.primaryField]).toLowerCase();
                    const fieldB = (b.id || b[section.primaryField]).toLowerCase();
                    if (fieldA < fieldB) return -1;
                    if (fieldA > fieldB) return 1;
                    return 0;
                });
                break;
            case "current":
                combinedData.sort((a, b) => {
                    const fieldA = (a.id || a[section.primaryField]).toLowerCase();
                    const fieldB = (b.id || b[section.primaryField]).toLowerCase();
                
                    if (a.current && !b.current) return -1;
                    if (!a.current && b.current) return 1;
                
                    if (fieldA < fieldB) return -1;
                    if (fieldA > fieldB) return 1;
                    return 0;
                });
                break;
            case "custom":
                combinedData.sort((a, b) => {
                    const sortOrder = section.sortOrder;
                    const fieldA = a[section.primaryField];
                    const fieldB = b[section.primaryField];
                    const indexA = sortOrder.indexOf(fieldA);
                    const indexB = sortOrder.indexOf(fieldB);
                    const maxIndex = sortOrder.length;
            
                    return (indexA === -1 ? maxIndex : indexA) - (indexB === -1 ? maxIndex : indexB);
                });
                break;
                break;
            default:
                break;
        }
    
        combinedData = combinedData.filter((item, index, self) =>
            index === self.findIndex((t) => (
                getKey(t) === getKey(item)
            ))
        );
    
        if (combinedData.length === 0) {
            combinedData.push({});
        }
    
        setCombinedData(combinedData);
    };

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (key, value) => {
        const keys = key.split('.').filter(Boolean);
        const newData = [...combinedData];
        let temp = newData;
        keys.forEach((k, index) => {
            if (index === keys.length - 1) {
                temp[k] = value;
                if (temp.isHistory) {
                    temp.isHistory = false;
                }
            } else {
                if (!temp[k]) {
                    temp[k] = {};
                }
                temp = temp[k];
            }
        });
        setEditedData(newData);
    };

    const handleBlur = async (index, subKey, value) => {

        try {
            const updatedData = editedData.map((item, i) => {
                const newItem = i === index ? { ...item, [subKey]: value } : item;
                delete newItem.isNew;
                return newItem;
            });
            const updatedMainData = { [section.subData]: updatedData };
            console.log(updatedData)
            await updateData(`patients/${patientId}/documents`, docId, updatedMainData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleAddRow = () => {
        const newRow = displayFields.reduce((acc, key) => ({ ...acc, [key]: '' }), { isNew: true, id: uuidv4() });
        setEditedData([...editedData, newRow]);
    };

    const handleHideRow = async (index) => {
        try {
            const updatedData = combinedData.map((item, i) => {
                const newItem = i === index ? { ...item, isHidden: !item.isHidden } : item;
                return newItem;
            });
            const updatedMainData = section.subData ? { ...data, [section.subData]: updatedData } : { data: updatedData };
            await updateData(`patients/${patientId}/documents`, docId, updatedMainData);
            setEditedData(updatedData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };


    const handleDeleteRow = async (index) => {
        try {
            const updatedData = combinedData.map((item, i) => {
                const newItem = i === index ? { ...item, isDeleted: !item.isDeleted } : item;
                return newItem;
            });
            const updatedMainData = section.subData ? { ...data, [section.subData]: updatedData } : { data: updatedData };
            await updateData(`patients/${patientId}/documents`, docId, updatedMainData);
            setEditedData(updatedData);
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const handleCopyToClipboard = () => {  
        const copyText = generateSectionExport(section, { [section.subData]: combinedData });
        copyToClipboard(Object.values(copyText)[0].data, true);
    };

    const resizeTextArea = useRef(debounce(() => {
        if (!isOpen) {
            const rows = document.querySelectorAll('.table-container tbody tr');
            rows.forEach(row => {
                const cells = row.querySelectorAll('td');
                let maxHeight = rowHeightAddition;
                cells.forEach(cell => {
                    const textarea = cell.querySelector('textarea');
                    if (textarea) {
                        maxHeight = Math.max(maxHeight, textarea.scrollHeight);
                        textarea.style.height = `${maxHeight + rowHeightAddition}px`;
                    }
                });
            });
        }
    }, debounceDelay)).current;


    return (
        <div className='standard-container' key={sectionKey}>
            <div className="edit-header-container">
                <div className="edit-header-left" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                    <h3 className="edit-header">
                        <span>
                            {isOpen ? <ArrowDropUpIcon className="accordion-icon" /> : <ArrowDropDownIcon className="accordion-icon" />}
                        </span>
                        {section.title}
                    </h3>
                </div>
           
                <div className="edit-header-right">      
           
                    {isOpen ? (
                        <Tooltip title="Copy to clipboard">
                            <ContentCopyIcon className="header-icon" style={iconMedium} onClick={handleCopyToClipboard} />
                        </Tooltip>
                    ) : ''}
                    {isOpen ? (
                        <Tooltip title={hiddenRowsVisible ? "Hide Hidden Rows" : "Show Hidden Rows"}>
                            {hiddenRowsVisible ? (
                                <VisibilityOffIcon className="header-icon" onClick={() => setHiddenRowsVisible(false)} style={iconMedium} />
                            ) : (
                                <VisibilityIcon className="header-icon" onClick={() => setHiddenRowsVisible(true)} style={iconMedium} />
                            )}
                        </Tooltip>
                    ) : ''}
                
                    {isOpen ? (
                        <Tooltip title="Show Row">
                            <AddBoxIcon className="header-icon" onClick={handleAddRow} style={iconMedium} />
                        </Tooltip>
                    ) : ''}
                </div>
            </div>
            {isOpen && (
                <div className='table-container'>
                    <table className={`standard-table ${section.editorStyle !== 'dataSection' ? 'wide' : 'thinner'}`}>
                        <colgroup>
                            {displayFields.map((key) => (
                                <col key={key} style={{ width: `${section.fields[key].width}%` }} />
                            ))}
                            <col style={{ width: '2.6%' }} />
                        </colgroup>
                        {section.showHeader !== false && (
                            <thead>
                                <tr>
                                    {Object.keys(section.fields).map((key) => (
                                        <th key={key} className={`column-${section.fields[key].width}`} data-original-key={key}>
                                            {section.fields[key].name}
                                        </th> 
                                    ))}
                                    <th style={{ width: '2.6%' }}></th>
                                </tr>
                            </thead>
                        )}
                        <tbody>
                            {combinedData.map((item, index) => (
                                (!item.isHidden || hiddenRowsVisible) && !item.isDeleted && (
                                    <React.Fragment key={index}>
                                        {renderValue(item, handleChange, handleBlur, index, displayFields, section, handleHideRow, handleDeleteRow)}
                                    </React.Fragment>
                                )
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
};

const ResizableTextarea = ({ value, handleChange, handleBlur, fieldKey, history, className }) => {
    const textareaRef = useRef(null);
    const isEmpty = value === undefined || value === null || value === '';

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + rowHeightAddition}px`;
        }
    }, [value, history]);

    return (
        <textarea
            ref={textareaRef}
            value={isEmpty ? history : value}
            className={`standard-textarea${isEmpty ? ' column-history' : ''} ${className}`}
            onChange={(e) => handleChange(fieldKey, e.target.value)}
            onBlur={() => handleBlur()}
            rows={1}
        />
    );
};

const renderValue = (item, handleChange, handleBlur, index, displayFields, section, handleHideRow, handleDeleteRow) => {

    return (
        <tr key={index}>
            {displayFields.map((subKey, subIndex) => (
                <React.Fragment key={subKey}>
                    <td className={`column-${section.fields[subKey].width} ${item.isHistory ? 'column-history' : ''}`}>
                        {
                        section.fields[subKey].type === 'check' ? (
                                <input
                                    type="checkbox"
                                    className="table-checkbox"
                                    checked={!!item[subKey]}
                                    onChange={(e) => handleChange(`${index}.${subKey}`, e.target.checked)}
                                    onBlur={() => handleBlur(index, subKey, item[subKey] || '')}
                                />
                            ) : (
                                <ResizableTextarea
                                    value={item[subKey] || ''}
                                    handleChange={(key, value) => handleChange(`${index}.${subKey}`, value)}
                                    handleBlur={() => handleBlur(index, subKey, item[subKey] || '')}
                                    fieldKey={`${index}.${subKey}`}
                                    history={item[subKey] || ''}
                                    className={`${subIndex === 0 ? 'column-first' : ''} ${item.isHistory ? 'column-history' : ''}`}
                                />
                            )
                        }
                    </td>
                </React.Fragment>
            ))}
            <td className='column-3'>
                <Tooltip title={item.isHidden ? "Show Row" : "Hide Row"}>
                    {item.isHidden ? (
                        <AddIcon className='table-icon green-icon' style={iconSmall} onClick={() => handleHideRow(index)} />
                    ) : (
                        <RemoveIcon className='table-icon' style={iconSmall} onClick={() => handleHideRow(index)} />
                    )}
                </Tooltip>
                <Tooltip title="Delete Row">
                <DeleteIcon className='table-icon red-icon' style={iconSmall} onClick={() => handleDeleteRow(index)} />
                </Tooltip>
            </td>
        </tr>
    );
};

export default EditableTableSection;