// Simple Data Table editor for basic data objects. Supports two columns, with the first one locked.

import React, { useState, useRef, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { updateData } from '../utils/firestoreService';
import { generateSectionExport } from '../utils/exportUtils';
import {processHistory, processHistoryData} from '../utils/historyProcessing';
import CopyToIcon from '@mui/icons-material/AutoMode';

const iconStyle = { cursor: 'pointer', marginLeft: '0.4rem', fontSize: '1rem' };

const rowHeightAddition = 8; // additional height to add to textarea rows

const capitalizeAndFormat = (str) => {
    return str
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const ResizableTextarea = ({ value, handleChange, handleBlur, fieldKey, history }) => {
    const textareaRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight + rowHeightAddition}px`;
        }
    }, [value, history]);

    const isEmpty = value === undefined || value === null || value === '';

    return (
        <textarea
            ref={textareaRef}
            value={isEmpty ? history : value}
            className={`standard-textarea column-100 ${isEmpty ? 'column-history' : ''}`}
            onChange={(e) => handleChange(fieldKey, e.target.value)}
            onBlur={() => handleBlur()}
            rows={1}
        />
    );
};

const renderValue = (value, handleChange, handleBlur, fieldKey, history) => {
    const { historyDataItem } = processHistoryData(history, fieldKey);

    if (typeof value === 'object' && value !== null) {
        return (
            <table className="standard-table" style={{ marginLeft: '20px' }}>
                <tbody>
                    {Object.keys(value).sort().map((subKey) => {
                        return (
                            <tr key={subKey}>
                                <td className='column-10 column-right'>{capitalizeAndFormat(subKey)}</td>
                                <td className={`column-100`}>
                                    {renderValue(
                                        value[subKey],
                                        handleChange,
                                        handleBlur,
                                        `${fieldKey}.${subKey}`,
                                        history
                                    )}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        );
    }
    return (
        <ResizableTextarea
            value={value}
            handleChange={handleChange}
            handleBlur={handleBlur}
            fieldKey={fieldKey}
            history={historyDataItem}
        />
    );
};

const EditableDataSection = ({ section, sectionKey, data, dataHistory, patientId, docId, copyToClipboard, copyToSection, ContentCopyIcon }) => {
    const [isOpen, setIsOpen] = useState(section.defaultOpen);
    const [editedData, setEditedData] = useState(data || {});

    const combinedHistory = processHistory(dataHistory || {}, section.data);

    const handleCopyToClipboard = () => {  
        const sectionData = generateSectionData(section, editedData, combinedHistory);
        copyToClipboard(sectionData, true);
    };

    const handleCopyToSection = () => {  
        const sectionData = generateSectionData(section, editedData, combinedHistory);
        copyToSection(sectionData, section.copyToSection);
    };

    function generateSectionData(section, editedData, combinedHistory) {
        const wrappedEditedData = { [section.data]: editedData };
        const processedCombinedHistory = {};
        Object.keys(combinedHistory).forEach(key => {
            const { historyDataItem } = processHistoryData(combinedHistory, key);
            processedCombinedHistory[key] = historyDataItem;
        });
        const wrappedCombinedHistory = [{ [section.data]: processedCombinedHistory }];
        const sectionExport = generateSectionExport(section, wrappedEditedData, wrappedCombinedHistory);
        return sectionExport[section.data].data;
    }

    const toggleAccordion = () => {
        setIsOpen(!isOpen);
    };

    const handleChange = (fieldKey, value) => {
        const keys = fieldKey.split('.');
        const newData = { ...editedData };
        let temp = newData;
        keys.forEach((k, index) => {
            if (index === keys.length - 1) {
                temp[k] = value;
            } else {
                if (!temp[k]) {
                    temp[k] = {};
                }
                temp = temp[k];
            }
        });
        setEditedData(newData);
    };

    const handleBlur = async () => {
        try {
            await updateData(`patients/${patientId}/documents`, docId, { [section.data]: editedData });
        } catch (error) {
            console.error('Error updating data:', error);
        }
    };

    const sortedKeys = Object.keys(editedData).sort((a, b) => {
        if (section.sort === 'alphabetical') {
            return a.localeCompare(b);
        }
        return 0;
    });

    const displayKeys = section.fields.length > 0 ? section.fields : sortedKeys;

    return (
        <div className='standard-container' key={sectionKey}>
            <div className="edit-header-container">
                <div className="edit-header-left" onClick={toggleAccordion} style={{ cursor: 'pointer' }}>
                    <h3 className="edit-header">
                        <span>
                            {isOpen ? <ArrowDropUpIcon className="accordion-icon" /> : <ArrowDropDownIcon className="accordion-icon" />}{section.title}
                        </span>
                    </h3>
                </div>
                <div className="edit-header-right">
                {isOpen && section.copyToSection ? <CopyToIcon style={iconStyle} onClick={handleCopyToSection} /> : ''}   
                {isOpen ? <ContentCopyIcon className="header-icon" style={iconStyle} onClick={handleCopyToClipboard} /> : ''}
                </div>
            </div>
            {isOpen && (
                <table className='standard-table'>
                    <tbody>
                        {displayKeys.map((key) => (
                            <tr key={key}>
                                <td className={`column-first column-gray column-${section.columnWidth}`}>{capitalizeAndFormat(key)}:</td>
                                <td className="column-100">{renderValue(editedData[key], handleChange, handleBlur, key, combinedHistory)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default EditableDataSection;