// Document.js
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { updateData, deleteData } from './utils/firestoreService';
import MenuComponent from './utils/menuComponent';

import EditableDataSection from './editors/EditableDataSection';
import EditableTextSection from './editors/EditableTextSection';
import EditableTableSection from './editors/EditableTableSection';

import ArticleIcon from '@mui/icons-material/Article';
import AsssignmentIcon from '@mui/icons-material/Assignment';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import { fetchDocumentData } from './utils/fetchDocumentData';
import ConfirmDocument from './utils/documentConfirmation';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

const renderSection = (section, documentData, documentHistory, copyToClipboard, copyToSection, ContentCopyIcon, patientId, docId, key) => {
    if (section.type === 'Confirmation' || section.type === 'StartEndTime') {
        return null;
    }
    
    switch (section.type) {
        case 'EditableDataSection':
            return (
                <EditableDataSection
                    key={key} 
                    section={section}
                    sectionKey={key || section.title}
                    data={documentData[section.data]}
                    dataHistory={documentHistory}
                    patientId={patientId}
                    docId={docId}
                    copyToClipboard={copyToClipboard}
                    copyToSection={copyToSection}
                    ContentCopyIcon={ContentCopyIcon}
                />
            );
        case 'EditableTextSection':
            return (
                <EditableTextSection
                    key={key} 
                    section={section}
                    sectionKey={key || section.title}
                    documentData={documentData}
                    copyToClipboard={copyToClipboard}
                    ContentCopyIcon={ContentCopyIcon}
                    patientId={patientId}
                    docId={docId}
                />
            );
        case 'EditableTableSection':
            return (
                <EditableTableSection
                    key={key} 
                    section={section}
                    sectionKey={key || section.title}
                    data={documentData}
                    dataHistory={documentHistory}
                    patientId={patientId}
                    docId={docId}
                    displayHistory={section.displayHistory}
                    copyToClipboard={copyToClipboard}
                    ContentCopyIcon={ContentCopyIcon}
                />
            );
        default:
            return null;
    }
};

const Document = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId, docId } = location.state || {};
    const [documentData, setDocumentData] = useState(null);
    const [documentHistory, setDocumentHistory] = useState(null);
    const [personName, setPersonName] = useState('');
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [title, setTitle] = useState('');
    const [isExportOpen, setIsExportOpen] = useState(false);
    const [isTranscriptOpen, setIsTranscriptOpen] = useState(false);
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [isEditingDate, setIsEditingDate] = useState(false);
    const [editableDate, setEditableDate] = useState('');
    const [isEditingStartTime, setIsEditingStartTime] = useState(false);
    const [isEditingEndTime, setIsEditingEndTime] = useState(false);
    const [editableStartTime, setEditableStartTime] = useState('');
    const [editableEndTime, setEditableEndTime] = useState('');
    const [therapyMinutes, setTherapyMinutes] = useState('');
    const [isEditingTherapyMinutes, setIsEditingTherapyMinutes] = useState(false);
    const [therapyType, setTherapyType] = useState('');
    const [isEditingTherapyType, setIsEditingTherapyType] = useState(false);
    const [mostRecentTherapy, setMostRecentTherapy] = useState(null); 

    // Effects

    useEffect(() => {
        if (!patientId || !docId) {
            navigate('/patient', { replace: true });
            return;
        }
        fetchDocumentData(patientId, docId, setPersonName, setDocumentData, setTitle, setDocumentHistory);
    }, [patientId, docId, navigate]);
    
    useEffect(() => {
        if (documentHistory && documentHistory.length > 0) {
            const therapyRecords = documentHistory.filter(record => record.therapyType);
            if (therapyRecords.length > 0) {
                const mostRecentRecord = therapyRecords.reduce((latest, record) => {
                    return new Date(record.documentDate) > new Date(latest.documentDate) ? record : latest;
                });
                setMostRecentTherapy(mostRecentRecord);
            }
        }
    }, [documentHistory]);

    useEffect(() => {
        const fetchDocumentTypes = async () => {
            try {
                const response = await fetch('/documentTypes');
                if (response.ok) {
                    const data = await response.json();
                    if (Array.isArray(data) && data.length > 0) {
                        setDocumentTypes(data);
                    } else {
                        console.error('Document types data is not an array or is empty:', data);
                    }
                } else {
                    console.error('Failed to fetch document types');
                }
            } catch (error) {
                console.error('Error fetching document types:', error);
            }
        };

        fetchDocumentTypes();
    }, []);

    const handleBack = () => {
        navigate('/Patient', { state: { patientId: patientId } });
    };

    const copyToClipboard = async (content, isHtml = false) => {
        try {
            if (isHtml) {
                await navigator.clipboard.write([
                    new ClipboardItem({
                        'text/html': new Blob([content], { type: 'text/html' })
                    })
                ]);
            } else {
                await navigator.clipboard.writeText(content);
            }
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    const copyToSection = async (content, field) => {
        if (!documentData || !field) {
            throw new Error("Invalid document data or field");
        }
        
        await updateData(`patients/${patientId}/documents`, docId, {
            [field]: content
        });

        setDocumentData({
            ...documentData,
            [field]: content
        }); 

        // possibly being blocked by history, may need a more complete check
    }

    // handlers 

    const handleTitleEdit = () => {
        setIsEditingTitle(true);
    };

    const handleDeleteDocument = async (docId) => {
            await deleteData(`patients/${patientId}/documents`, docId);
            navigate('/Patient', { state: { patientId: patientId } });
    };

    const handleDateSave = async () => {
        const datePattern = /^(0[1-9]|1[0-2])[-\/](0[1-9]|[12][0-9]|3[01])[-\/](\d{4})$/;

        if (datePattern.test(editableDate)) {
            try {
                const selectedDate = editableDate;
                await updateData(`patients/${patientId}/documents`, docId, {
                    documentDate: selectedDate,
                    modifiedAt: new Date(selectedDate),
                });

                setDocumentData({
                    ...documentData,
                    documentDate: selectedDate
                });

                setIsEditingDate(false);
            } catch (error) {
                console.error('Error updating date:', error);
            }
        } else {
            alert("Invalid date format. Please use MM-DD-YYYY or MM/DD/YYYY for date.");
        }
    };

    const handleTimeSave = async (field) => {
        const timePattern = /^(0?[1-9]|1[0-2]):([0-5]\d)\s?(AM|PM)$/i;

        let selectedTime;
        if (field === 'startTime' && timePattern.test(editableStartTime)) {
            selectedTime = editableStartTime;
        } else if (field === 'endTime' && timePattern.test(editableEndTime)) {
            selectedTime = editableEndTime;
        } else {
            alert("Invalid time format. Please use HH:MM AM/PM for time.");
            return;
        }

        try {
            await updateData(`patients/${patientId}/documents`, docId, {
                [field]: selectedTime
            });

            setDocumentData({
                ...documentData,
                [field]: selectedTime
            });

            if (field === 'startTime') {
                setIsEditingStartTime(false);
            } else if (field === 'endTime') {
                setIsEditingEndTime(false);
            }
        } catch (error) {
            console.error('Error updating time:', error);
        }
    };

    const handleTitleSave = async () => {
        try {
            await updateData(`patients/${patientId}/documents`, docId, { title });
            setDocumentData({ ...documentData, title });
            setIsEditingTitle(false);
        } catch (error) {
            console.error('Error updating title:', error);
        }
    };

    const handleTherapyTimeSave = async (field, value) => {
        try {
            if (field === 'therapyMinutes' && isNaN(value)) {
                alert('Only use numbers for the Minutes of Therapy field.');
                return;
            }
    
            await updateData(`patients/${patientId}/documents`, docId, {
                [field]: value
            });
            setDocumentData({
                ...documentData,
                [field]: value
            });
    
            if (field === 'therapyMinutes') {
                setIsEditingTherapyMinutes(false);
            } else if (field === 'therapyType') {
                setIsEditingTherapyType(false);
            }
        } catch (error) {
            console.error('Error updating therapy data:', error);
        }
    };

    const handleExportDocument = async () => {
        try {
            await fetchDocumentData(patientId, docId, setPersonName, setDocumentData, setTitle, setDocumentHistory);
            setIsExportOpen(true);
        } catch (error) {
            console.error('Error fetching document data:', error);
        }
    };

    const documentComplete = () => {
        setIsExportOpen(false);
        navigate('/Patient', { state: { patientId: patientId } });
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    

    return (documentData && documentTypes && (
        <div className='page-container'>

            {/* Page header */}
            <div className='header-container'>
                <button className='back-button' onClick={handleBack}>&larr;</button>
                <div className='page-title'>Document Review: <strong>{personName}</strong></div>

                {documentTypes.find(type => type.id === documentData.typeId)?.format === 'audio' && (
                    <div title="Export Note" className="header-button" onClick={handleExportDocument}>
                        <AsssignmentIcon className="header-icon" /> Export Note
                    </div>
                )}

                {isExportOpen && (
                    <div className="modal" onClick={() => setIsExportOpen(false)}>
                        <span className="close" onClick={() => setIsExportOpen(false)}>&times;</span>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <ConfirmDocument
                                documentData={documentData}
                                documentType={documentTypes.find(type => type.id === documentData.typeId)}
                                patientId={patientId}
                                dataHistory={documentHistory}
                                docId={docId}
                                documentComplete={documentComplete}
                                personName={personName}
                            />
                        </div>
                    </div>
                )}

                <div title="View Transcript" className="header-button" onClick={() => setIsTranscriptOpen(true)}>
                    <ArticleIcon className="header-icon" />
                </div>
                
                {isTranscriptOpen && (
                    <div className="modal" onClick={() => setIsTranscriptOpen(false)}>
                        <span className="close" onClick={() => setIsExportOpen(false)}>&times;</span>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <ContentCopyIcon title='Copy Transcript' onClick={() => copyToClipboard(documentData.processed_text ?? documentData.cleaned_text ?? documentData.text)}></ContentCopyIcon>
                            <div>{documentData.processed_text ?? documentData.cleaned_text ?? documentData.text}</div>
                        </div>
                    </div>
                )}

                <div>
                    <DeleteIcon className='header-icon' onClick={() => setDeleteConfirmOpen(true)} ></DeleteIcon>
                </div>

                <MenuComponent patientId={patientId} />

                {isDeleteConfirmOpen && (
                    <div className="modal" onClick={() => setDeleteConfirmOpen(false)}>
                        <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className='modal-title'>Are you sure you want to delete this document?</div>
                            <div className="modal-button-container">
                                <button className="button-continue" onClick={() => setDeleteConfirmOpen(false)}>
                                    Cancel
                                </button>
                                <button className="button-delete" onClick={() => {
                                    handleDeleteDocument(docId);
                                    setDeleteConfirmOpen(false);
                                }}>Yes, Delete</button>
                            </div>
                        </div>
                    </div>
                )}

            </div>

            <div className='content-container'>

                {/* Document header */}
                <div className='document-title'>
                    {isEditingTitle ? (
                        <span>
                            <input
                                type="text"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                onBlur={handleTitleSave}
                                autoFocus
                                className="document-title-input"
                            />
                        </span>
                    ) : (
                        <span onClick={handleTitleEdit}>{title}</span>
                    )}
                </div>

                <div className='datetime-header'>

                    {/* Document date */}

                    <div className='document-date'>
                        {isEditingDate ? (
                            <input
                                type="text"
                                value={editableDate}
                                onChange={(e) => setEditableDate(e.target.value)}
                                onBlur={() => handleDateSave()}
                                autoFocus
                                className="document-date-input"
                            />
                        ) : (
                            <span onClick={() => {
                                setIsEditingDate(true);
                                setEditableDate(documentData.documentDate);
                            }}>
                                {documentData.documentDate}
                            </span>
                        )}
                    </div>

                    {/* Start and End Times */}
                    {documentTypes.find(type => type.id === documentData.typeId)?.sections.some(section => section.type === 'StartEndTime') && (
                        <div className='document-times'>
                                <>
                                    <div className='document-date'>
                                        <small>Start Time:</small>
                                        {isEditingStartTime ? (
                                            <input
                                                type="text"
                                                value={editableStartTime}
                                                onChange={(e) => setEditableStartTime(e.target.value)}
                                                onBlur={() => handleTimeSave('startTime')}
                                                autoFocus
                                                className="document-date-input"
                                            />
                                        ) : (
                                            <span onClick={() => {
                                                setIsEditingStartTime(true);
                                                setEditableStartTime(documentData.startTime);
                                            }}>
                                                {documentData.startTime}
                                            </span>
                                        )}
                                    </div>
                                    <div className='document-date'>
                                        <small>End Time:</small>
                                        {isEditingEndTime ? (
                                            <input
                                                type="text"
                                                value={editableEndTime}
                                                onChange={(e) => setEditableEndTime(e.target.value)}
                                                onBlur={() => handleTimeSave('endTime')}
                                                autoFocus
                                                className="document-date-input"
                                            />
                                        ) : (
                                            <span onClick={() => {
                                                setIsEditingEndTime(true);
                                                setEditableEndTime(documentData.endTime);
                                            }}>
                                                {documentData.endTime}
                                            </span>
                                        )}
                                    </div>
                                </>
                        </div>
                    )}

                    {/* Session Type and Timing */}   
                    {documentTypes.find(type => type.id === documentData.typeId)?.sections.some(section => section.type === 'TherapyTiming') && (

                       <div className='document-times'>
                            <>
                                <div className='document-date'>
                                    <small>Minutes of Therapy:</small>
                                    {isEditingTherapyMinutes ? (
                                        <input
                                            type="text"
                                            value={therapyMinutes}
                                            onChange={(e) => setTherapyMinutes(e.target.value)}
                                            onBlur={() => handleTherapyTimeSave('therapyMinutes', therapyMinutes)}
                                            autoFocus
                                            className="document-time-input"
                                        />
                                    ) : (
                                        <span onClick={() => {
                                            setIsEditingTherapyMinutes(true);
                                            setTherapyMinutes(documentData.therapyMinutes || '');
                                        }}>
                                            {documentData.therapyMinutes || '---'}
                                        </span>
                                    )}
                                </div>
                                <div className='document-therapy'>
                                    <small>Therapy Type: </small>
                                    {isEditingTherapyType ? (
                                        <input
                                            type="text"
                                            value={therapyType}
                                            onChange={(e) => setTherapyType(e.target.value)}
                                            onBlur={() => handleTherapyTimeSave('therapyType', therapyType)}
                                            autoFocus
                                            className="document-therapy-input"
                                        />
                                    ) : (
                                        <span onClick={() => {
                                            setIsEditingTherapyType(true);
                                            setTherapyType(documentData.therapyType || mostRecentTherapy?.therapyType || '');
                                        }}>
                                            {documentData.therapyType || mostRecentTherapy?.therapyType || '---'}
                                        </span>
                                    )}
                                </div>
                            </>
                        </div>
                    )}

                    </div>
                    
                    {documentTypes.find(type => type.id === documentData.typeId)?.sections.map((section, index) =>
                        renderSection(
                            section,
                            documentData,
                            documentHistory,
                            copyToClipboard,
                            copyToSection,
                            ContentCopyIcon,
                            patientId,
                            docId,
                            `${section.title}-${index}` 
                        )
                    )}
        <button className='scroll-to-top-button' onClick={scrollToTop}>
            <ArrowUpwardIcon />
        </button>
            </div>
        </div>
    )
    );

};

export default Document;