import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { deleteData, getDocument, getDocuments } from './utils/firestoreService';
import MenuComponent from './utils/menuComponent';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SearchIcon from '@mui/icons-material/Search';
import AssignmentIcon from '@mui/icons-material/Assignment';
import MicIcon from '@mui/icons-material/Mic';
import { Pagination } from '@mui/material';

let patientData = null;

const Patient = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { patientId } = location.state || {};
    const [personName, setPersonName] = useState('');
    const [patientDocuments, setPatientDocuments] = useState([]);
    const [historySummary, setHistorySummary] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const hasFetchedDataRef = useRef(false);
    const [isDeleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
    const [deleteDocId, setDeleteDocId] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const documentsPerPage = 20;

    const handleBack = () => {
        navigate(-1);
    };

    useEffect(() => {
        if (hasFetchedDataRef.current) return;

        if (!patientId) {
            navigate('/patient', { replace: true });
            return;
        }

        const fetchPatientData = async () => {
            try {
                hasFetchedDataRef.current = true;

                patientData = await getDocument(`patients`, patientId);

                if (patientData) {
                    const formattedPersonName = `${patientData.firstName} ${patientData.lastName.charAt(0)}.`;
                    setPersonName(formattedPersonName);

                    let documents = await getDocuments(`patients/${patientId}/documents`);

                    documents = documents.sort((a, b) => b.modifiedAt.seconds - a.modifiedAt.seconds);

                    setPatientDocuments(documents);

                    if (documents.length > 0) {
                        const filteredDocuments = documents.filter(doc => doc.history_summary);
                        if (filteredDocuments.length > 0) {
                            const mostRecent = filteredDocuments.reduce((latest, doc) => {
                                return doc.createdAt.seconds > latest.createdAt.seconds ? doc : latest;
                            }, filteredDocuments[0]);
                            setHistorySummary(mostRecent);
                        }
                    }

                } else {
                    console.error('No such patient!');
                }
            } catch (error) {
                console.error('Error fetching patient data:', error);
            }
        };

        fetchPatientData();

    }, [patientId, navigate]);

    const handleDeleteDocument = async (docId) => {
        await deleteData(`patients/${patientId}/documents`, docId);
        setPatientDocuments(prevDocs => prevDocs.filter(doc => doc.id !== docId));
    };

    const handleDocumentClick = (docId) => {
        navigate('/document', { state: { patientId, docId } });
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
    };

    const searchInObject = (obj, searchTerm) => {
        if (!obj) return false;
        return Object.values(obj).some(value => {
            if (typeof value === 'string') {
                return value.toLowerCase().includes(searchTerm.toLowerCase());
            }
            if (typeof value === 'object') {
                return searchInObject(value, searchTerm);
            }
            return false;
        });
    };
    
    const filteredDocuments = patientDocuments.filter(doc => {
        const searchTerm = searchQuery.toLowerCase();
        return (
            doc.title?.toLowerCase().includes(searchTerm) ||
            doc.document_summary?.toLowerCase().includes(searchTerm) ||
            doc.documentDate?.toLowerCase().includes(searchTerm) ||
            doc.interval_history?.toLowerCase().includes(searchTerm) ||
            doc.history_goals?.toLowerCase().includes(searchTerm) ||
            doc.history_summary?.toLowerCase().includes(searchTerm) ||
            doc.cleaned_text?.toLowerCase().includes(searchTerm) ||
            searchInObject(doc.sections, searchTerm)
        );
    });
    
    const indexOfLastDocument = currentPage * documentsPerPage;
    const indexOfFirstDocument = indexOfLastDocument - documentsPerPage;
    const currentDocuments = filteredDocuments.slice(indexOfFirstDocument, indexOfLastDocument);
    const totalPages = Math.ceil(filteredDocuments.length / documentsPerPage);

    return (
        <div className='page-container'>
            <div className='header-container'>
                <button onClick={handleBack}>&larr;</button>
                {personName && (
                    <div className='page-title'>
                        Patient Records for {personName}
                    </div>
                )}
                {personName && <MenuComponent patientId={patientId} />}
            </div>
            <div className='patient-content-container'>
                <div className="documents-list-container">
                    <div className='patient-header-container'>
                        <div className='section-title'>
                            Recent Documents
                        </div>
                        <div className='header-search'>
                            <div className='header-search-wrapper'>
                                <SearchIcon className='header-search-icon' />
                                <input
                                    type="text"
                                    placeholder=""
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    className='header-search-input'
                                />
                            </div>
                        </div>
                    </div>
                    <table className="standard-table">
                        <thead>
                            <tr>
                                <th className='column-5'></th>
                                <th className='column-60'></th>
                                <th className='column-5'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentDocuments.length === 0 ? (
                                <tr>
                                    <td colSpan="3" style={{ textAlign: 'center' }}>No documents found...</td>
                                </tr>
                            ) : (
                                currentDocuments.map((doc, index) => (
                                    <tr key={index}>
                                        <td>
                                            <button className='table-icon-lighter' onClick={() => handleDocumentClick(doc.id)} style={{ border: 'none', background: 'none', padding: 0, cursor: 'pointer' }}>
                                                {doc.typeId === 1 || doc.typeId === 2 ? (
                                                    <MicIcon className="table-icon" />
                                                ) : (
                                                    <AssignmentIcon className="table-icon" />
                                                )}
                                            </button>
                                        </td>
                                        <td>
                                            <h4>
                                                <strong onClick={() => handleDocumentClick(doc.id)} style={{ cursor: 'pointer' }}>
                                                    {doc.title}
                                                </strong>
                                                <span className='summary-small' style={{ marginLeft: '10px' }}>
                                                    {doc.documentDate}
                                                </span>
                                            </h4>
                                            <div className='summary-small'>
                                                {doc.short_summary ? (doc.short_summary.length > 220 ? doc.short_summary.substring(0, 220) + '...' : doc.short_summary) : ''}
                                            </div>
                                        </td>
                                        <td>
                                            <DeleteOutlineIcon className='table-icon-lighter' onClick={() => { setDeleteConfirmOpen(true); setDeleteDocId(doc.id); }} ></DeleteOutlineIcon>
                                        </td>
                                        {isDeleteConfirmOpen && deleteDocId === doc.id && (
                                            <div className="modal" onClick={() => setDeleteConfirmOpen(false)}>
                                                <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                                    <div className='modal-title'>Are you sure you want to delete this document? In this preMVP version there's no undo!</div>
                                                    <div className="modal-button-container">
                                                        <button className="button-cancel" onClick={() => setDeleteConfirmOpen(false)}>
                                                            Cancel
                                                        </button>
                                                        <button className="button-delete" onClick={() => {
                                                            handleDeleteDocument(doc.id);
                                                            setDeleteConfirmOpen(false);
                                                        }}>Yes, Delete</button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    <div className="pagination-controls">
                        <Pagination
                            count={totalPages}
                            page={currentPage}
                            onChange={(event, value) => setCurrentPage(value)}
                            color="primary"
                            variant="outlined"
                            size="small"
                        />
                    </div>
                </div>
    
                <div className="patient-details-container">
                    <div className='patient-header-container'>
                        <div className='section-title'>
                            Patient Details
                        </div>
                    </div>
                    {patientData && (
                        <div className='patient-details'>
                            <p><strong>Full Name:</strong> {patientData.firstName} {patientData.lastName}</p>
                            <p><strong>Date of Birth:</strong> {patientData.dateOfBirth}</p>
                            <p><strong>Gender:</strong> {patientData.gender}</p>
                            <br />
                            <p><strong>Goals:</strong></p>
                            {historySummary && historySummary.history_goals ? (
                                <p>{historySummary.history_goals}</p>
                            ) : (
                                <p>Goals are not yet available.</p>
                            )}
                            <br />
                            <p><strong>History Summary:</strong></p>
                            {historySummary && historySummary.history_summary ? (
                                <div>
                                    <p>{historySummary.history_summary}</p>
                                </div>
                            ) : (
                                <p>History is not yet available.</p>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Patient;